body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #6c7884
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p{
  font-weight: 400;
  line-height: 1.5;
  font-size: 1rem;

}

h2 {
  font-weight: 600;
  margin: 0;
}

li {
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 400;
  padding-bottom: 10px;
}

.btn {
  background: #0074cc;
  border: none;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 300;
  line-height: 1.5;
  margin: 0;
  overflow: hidden;
  padding: 8px 15px 6px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background .25s ease, border .25s ease;
  vertical-align: middle;
  z-index: 0;
}

.helpButton {
  left: 12px !important;
}

.dockableContainer {
  left: 12px !important;
}