#spTabs {
    display: block;
    height: 100%;
}

#spVirtualCalc {
    height: 100%;
    width: auto;
}

.calc__body {
    height: 100%;
}

#root {
    height: 95vh;
}

div.nav-menu {
    width: 78%;
    margin: 30px auto 0;
    display: flex;
    justify-content: space-between;    
}

div.nav-menu button {
    background-color: white;
    padding: 10px;
    color: #0074cc;
    border: 1px solid #0074cc;
    font-size: 16px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    letter-spacing: 2px;
    transition: .3s;
}

div.nav-menu button#spExam {
    background-color: #5e4687;
    color: white;
    border-color: #5e4687;
}

div.nav-menu button#spExam:hover {
    background-color: #473566;
    border-color: #473566;
}

div.nav-menu button[focused="true"] {
    background-color: #0074cc;
    color: white;

}

#main-content {
    margin: auto;
    min-height: 600px;
}
