.spPdfPage {
    background-color: #E4E4E4;
    margin: 10px;
    padding: 10px;
}

.react-pdf__Page__textContent {
    display: none;
}

.react-pdf__Page__canvas {
    border: 4px solid black;
}