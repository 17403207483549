div.twin-pane-container {
  display: flex;
  padding: 30px;
  min-height: 600px;
}

section.leftPane, section.rightPane {
  width: calc(50% - 0.5px); 
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

section.leftPane {
  border-right: 1px solid #e4eaec;
  padding-right: 30px;
}

section.rightPane {
  padding-left: 30px;
}

div.column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  vertical-align: center;
  height: 100%;
}
.screen_share_example{
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}
div.column > .btn{
  padding: 10px;
}
div.pass > .icn{
  fill: #46be8a;
  align-self: center;
}

div.column .bounds{
  display: none;
  height: 60%;
  width: 100%;
  align-self: baseline;
  padding-left: 15px;
}

main {
  width: 80%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 5px rgba(0,0,0,0.2);
  border-radius: 10px;
  justify-content: space-evenly;
}

.sp-heading {
  font-size: 36px;
  color: #6c7884;
  text-align: center;
  margin: 30px 0;
}

div.stepper-container {
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  padding: 50px 0;
}

aside.left, aside.right {
  width: 10%;
}